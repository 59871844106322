import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TournamentAdvancedMatchSetRoundsMode = _resolveComponent("TournamentAdvancedMatchSetRoundsMode")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TournamentAdvancedMatchSetRoundsMode, {
      msg: "Kamptavle",
      readOnlyFlag: _ctx.advancedMatchSetRoundsModeData.readOnlyFlag,
      playerToStartFirstRound: _ctx.advancedMatchSetRoundsModeData.startingPlayer,
      submatchWinAtNumberOfWonSets: _ctx.advancedMatchSetRoundsModeData.submatchWinAtNumberOfWonSets,
      matchIndexNumber: _ctx.advancedMatchSetRoundsModeData.matchIndexNumber,
      matchSet: _ctx.advancedMatchSetRoundsModeData.matchSet,
      matchId: _ctx.advancedMatchSetRoundsModeData.matchId,
      playerTeamNames: (_ctx.advancedMatchSetRoundsModeData.matchTeamNames),
      matchTeamPlayersNames: (_ctx.advancedMatchSetRoundsModeData.matchTeamPlayersNames),
      matchTeamSecondPlayersNames: (_ctx.advancedMatchSetRoundsModeData.matchTeamSecondPlayersNames),
      matchIsDouble: (_ctx.advancedMatchSetRoundsModeData.matchIsDouble),
      selectedMatchId: _ctx.advancedMatchSetRoundsModeData.selectedMatchId,
      homeTeamResult: _ctx.advancedMatchSetRoundsModeData.homeTeamResult,
      awayTeamResult: _ctx.advancedMatchSetRoundsModeData.awayTeamResult,
      tournamentCategoryId: _ctx.advancedMatchSetRoundsModeData.tournamentCategoryId
    }, null, 8, ["readOnlyFlag", "playerToStartFirstRound", "submatchWinAtNumberOfWonSets", "matchIndexNumber", "matchSet", "matchId", "playerTeamNames", "matchTeamPlayersNames", "matchTeamSecondPlayersNames", "matchIsDouble", "selectedMatchId", "homeTeamResult", "awayTeamResult", "tournamentCategoryId"])
  ]))
}