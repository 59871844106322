
import { Options, Vue } from 'vue-class-component'
import TournamentAdvancedMatchSetRoundsMode from '@/components/Tournaments/TournamentAdvancedMatchSetRoundsMode/index.vue' // @ is an alias to /src

  @Options({
    components: {
      TournamentAdvancedMatchSetRoundsMode
    }
  })
export default class TournamentMatchBoardView extends Vue {
  private advancedMatchSetRoundsModeData = { readOnlyFlag: false, startingPlayer: false, matchIndexNumber: 0, matchSet: [0, 0], matchId: 0, submatchWinAtNumberOfWonSets: 3, matchHomeTeamPlayer: '' as string | null, matchHomeTeamPlayer2: '' as string | null, matchGuestTeamPlayer: '' as string | null, matchGuestTeamPlayer2: '' as string | null, matchIsDouble: false, matchTeamNames: [] as string[], matchTeamPlayersNames: [] as string[], matchTeamSecondPlayersNames: [] as string[], selectedMatchId: null as number | null, homeTeamResult: 0, awayTeamResult: 0, tournamentCategoryId: 0 }

  beforeMount () {
    const data = localStorage.getItem('advancedMatchSetRoundsModeData')
    if (data) {
      this.advancedMatchSetRoundsModeData = JSON.parse(data)
    }
  }
}
